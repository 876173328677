import React from "react"

import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import SignUpEmail from "../../../components/signUpEmail";
import img1 from "../../../images/marketing-services/pay-per-click/pay-per-click-img-1.svg";
import img2 from "../../../images/marketing-services/pay-per-click/pay-per-click-img-2.svg";
import img3 from "../../../images/marketing-services/pay-per-click/pay-per-click-img-3.svg";
import AccordionComponent from "../../../components/AccordionComponent"


const webDesignPage = () => (
  <Layout css="payPerClickPageCss">
    <SEO title="PPC Management Boston"
         description={"Our Boston-based PPC Management experts can help you crush your online advertising goals. Turn those clicks into customers."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1><span className={"red"}>PAY PER CLICK </span>BOSTON</h1>
          <div className={"subtitle"}>
            <p>The field of digital marketing is ever-growing and features new competitors daily. Everyone (including
              Avantcy Marketing) aims to be at the top of their niche. A way to attain this feat and increase your
              chances of generating leads is through better visibility. One of the reasons our Boston PPC management
              team was established is to help you maximize your digital marketing potential.</p>
            <p>Among the things you can do to ensure better visibility, SEO and PPC are better known. The main reason
              for that is because they are highly effective and they are functional. SEO offers an avenue for better
              visibility that requires investing less time, capital, and still provide maximal results.</p>
            <p>Pay per click, on the other hand, offers an immediate solution to increasing your ROI. And what better
              place to get optimal PPC agency services than with the best— Avantcy Marketing. Before you read on, you
              need to know that we offer stand-alone or combined SEO and PPC services. Your first duty when working with
              our Boston PPC management system is to identify the approach that works better for your business.</p>
          </div>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"d-flex justify-content-center flex-column-mobile"}>
          <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
            <img alt={""} src={img2} id={"choosing-pic"}/>
          </div>
          <div className={"flex-equal-width m-3 text-align-center"}>
            <h2>WHAT IS PAY PER CLICK ADVERTISING?</h2>
            <p className={"text-align-left"}>Pay Per Click advertisement or PPC, like in its name, is a paid search that only require
              advertisers to pay when any user clicks on their PPC campaign. Our Boston PPC management not only will help you maximize PPC
              opportunities on Google, and it also helps with:</p>
            <ul className={"text-align-left"}>
              <li><strong>Building brand awareness</strong></li>
              <li><strong>Gaining attention from target audience categories</strong></li>
              <li><strong>Promoting brand deals and more</strong></li>
            </ul>
            <p className={"text-align-left"}>It allows you, as a business or brand owner to make your products and
              services visible to your target audience. Avantcy Marketing also works as a pay per click ads management
              agency. We set you up to place ads strategically on numerous online marketing platforms. Contrary to
              popular opinion, our PPC marketing strategy at Avantcy Marketing isn't restricted to Google PPC services.
              For us, PPC includes paid advert placement on:</p>
            <ul className={"text-align-left"}>
              <li><strong>Google search engine results page (SERPs)</strong></li>
              <li><strong>Advert placement on other sites like (LinkedIn, YouTube, Instagram, Facebook, and other social
                media sites)</strong></li>
              <li><strong>Google display networks and more</strong></li>
            </ul>
            <p className={"text-align-left"}>Multiple types of paid search marketing campaigns will promote your
              services and build your online presence with your target audience. Depending on your goals, you can use a
              number of these strategies to boost your online presence.</p>
            <p className={"text-align-left"}>As you share your goals, our team will work with you to help you select the
              better option.</p>
          </div>
        </div>
        <AccordionComponent label="Display PPC Adverts">
          <p>You can maximize our display pay per click adverts to reach more users. It works by putting up adverts that
            target visitors to industry-related sites. Display ads use pictures and texts to get web users' attention
            and generate potential leads.</p>
        </AccordionComponent>
        <AccordionComponent label="Search PPC Adverts">
          <p>Are you looking for new customers with a tried and tested advertising structure? Search ads under our
            Boston PPC management team are the way to go. The reason is that search advertising brings your brand to
            people already searching for it. Maximizing this type of PPC marketing puts you at a significant advantage
            in lead generation and sustainable marketing.</p>
        </AccordionComponent>
        <AccordionComponent label="Remarketing Adverts">
          <p>Do you want to double your turnovers? Remarketing ads offer room for you to remind previous site visitors
            to return and potentially become conversions. The best part is that it is cheaper than search PPC adverts
            since the competition isn't too rigid.</p>
        </AccordionComponent>
        <AccordionComponent label="Social Media PPC Adverts">
          <p>Avantcy’s Boston PPC management helps your company engage one of the fastest-growing pay per click
            advertising channels to your advantage. If your brand has an excellent social media presence and a massive
            target audience base, we can optimize social media marketing. These adverts will reach your audience on
            select social media platforms in line with their interests.</p>
        </AccordionComponent>
        <AccordionComponent label="In-stream (YouTube) Adverts">
          <p>In-stream adverts are commonly known as YouTube ads, and more recently, Facebook launched their version.
            These adverts appear in videos, display networks, or search results. Avantcy can help you maximize in-stream
            ads with the best natural spots for more effective marketing efforts.</p>
        </AccordionComponent>
        <AccordionComponent label="Shopping Adverts">
          <p>If you already use Boston PPC management and have many products, you may be interested in Google shopping
            adverts. They allow potential customers to view the products and their prices of individual products on
            their Google SERPs. This means that visitors on your site know what they want and are coming with an intent
            of purchase.</p>
        </AccordionComponent>
        <AccordionComponent label="Pay Per Lead (Local Services) Adverts">
          <p>If you want to avoid paying for clicks that do not eventually become leads, then you can try local services
            ads. It has a pay per lead plan instead of the PPC plan, so you only pay for clicks that become leads.</p>
        </AccordionComponent>
        <AccordionComponent label="Sponsored Adverts">
          <p>Do you want to reach your leads in their inbox and give them the means to contact you immediately? Avantcy
            Marketing, as a Boston PPC management agency, offers sponsored ads or Gmail sponsored Promotions (GSP) which
            is perfect for you. We can help you set your GSP, maximize it, and start getting conversions at any point in
            your sales funnel.</p>
        </AccordionComponent>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
        <div className={"flex-equal-width m-3 text-align-center"}>
          <h2>HOW GOOGLE ADS WORK FOR YOUR BUSINESS</h2>
          <p className={"text-align-left"}>Google PPC services use keywords and services like Google Adwords. Adwords
            helps in estimating the value of specific key phrases and words and assigning prices to them in line
            with:</p>
          <ul className={"text-align-left"}>
            <li><strong>Search volume</strong></li>
            <li><strong>Competition level</strong></li>
            <li><strong>Difficulty level</strong></li>
          </ul>
          <p className={"text-align-left"}>Due to limited slots, competitors have to undergo auctioning. The ad auction
            is an automatic process where search engines pass advertisements on their SERPs through a test of validity
            and relevance. To get a lower cost per click (CPC) and upgrade your ad position, you will need a quality
            score that is based on:</p>
          <ul className={"text-align-left"}>
            <li><strong>The inclusion of valuable keyword placement</strong></li>
            <li><strong>Having a search engine optimized landing page</strong></li>
            <li><strong>The relevance of content to a target audience</strong></li>
            <li><strong>Number of click-through rates</strong></li>
          </ul>
          <p className={"text-align-left"}>In ensuring this target is met for your business to grow, you will need
            in-depth auditing. However, Avantcy Marketing is here to make things easier for you by taking the burden of
            inspection off your shoulders. You may now focus on growing your company and setting new goals while we
            handle all the groundwork.</p>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img3} id={"choosing-pic"}/>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default webDesignPage;
