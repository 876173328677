import React, { useState } from 'react'
import '../styles/components/AccordionStyles.css'
import upArrow from "../images/up-arrow-icon.png";
import downArrow from "../images/down-arrow-icon.png";

const AccordionComponent = ({children, label}) => {
  const [show, setShow] = useState(false);

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={() => setShow(!show)}>
        <h5>{label}</h5>
        <div className="accordion-header-button">
          {show ? (
            <img src={upArrow} alt={"Arrow pointing up."}/>
          ) : (
              <img src={downArrow} alt={"Arrow pointing down."}/>
          )}
        </div>
      </div>
      {show && <div className="accordion-body">
        {children}
      </div>}
    </div>
  )
};

export default AccordionComponent
